import { PopUp } from '@/components/base/PopUp'
import { Text3 } from '@/components/base/Text'
import { Modal } from '@/components/base/Modal'
import { memo, useEffect } from 'react'
import { useApp } from '@/hooks/useApp'
import { AccentButton, SecandaryButton } from '@/components/base/Button'
import spaceStyle from '@/styles/space'
import { fontSize, fontWeight } from '@/styles/font'
import { buttonSize } from '../Button/Base'

interface Props {
  display: boolean
  msg: string
  submitTitle?: string
  cancelTitle?: string
  onClose: () => void
  onSubmit: () => void
}

const CommonPopup = ({
  display,
  msg,
  submitTitle,
  cancelTitle,
  onClose,
  onSubmit
}: Props): JSX.Element => {
  useEffect(() => {
    if (display) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [display])

  const app = useApp()

  return (
    <Modal isOpen={display}>
      <PopUp
        onClickClose={onClose}
        padding={{ horizontal: spaceStyle.XL, top: spaceStyle.XL }}
      >
        <>
          <Text3
            textColor={app.theme.mainTextColor}
            margin={{ vertical: spaceStyle.XXL }}
          >
            {msg}
          </Text3>
          <SecandaryButton
            width="100%"
            padding={{ all: spaceStyle.AUTO }}
            height={buttonSize.M}
            fontSize={fontSize.S}
            fontWeight={fontWeight.Bold}
            margin={{ bottom: spaceStyle.M }}
            onClick={(e) => {
              e.stopPropagation()
              onSubmit()
              onClose()
            }}
          >
            {submitTitle ?? 'はい'}
          </SecandaryButton>
          <AccentButton
            outlined
            width="100%"
            height={buttonSize.M}
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
            borderColor={app.theme.frameColor}
            shadown={false}
            fontSize={fontSize.S}
            fontWeight={fontWeight.Bold}
            textColor={app.theme.subTextColor}
          >
            {cancelTitle ?? 'キャンセル'}
          </AccentButton>
        </>
      </PopUp>
    </Modal>
  )
}

export default memo(CommonPopup)
