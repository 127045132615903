import { device } from '@/styles/device'
import { type ReactNode, type ReactPortal } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
`

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  width: 95%;
  height: 95%;
  @media ${device.mobile} {
    border-radius: 0px;
    width: 100%;
    height: 100%;
  }
  max-width: 1080px;

  z-index: 10000; /* Ensure it's above .modal-overlay */
`

export interface ModalProps {
  isOpen: boolean
  onClickBackground?: () => void
  children?: ReactNode
}

const Modal = ({
  isOpen,
  onClickBackground,
  children
}: ModalProps): ReactPortal | null => {
  if (!isOpen) {
    return null
  }

  return ReactDOM.createPortal(
    <>
      <ModalOverlay
        id="modalOverlay"
        onClick={(e) => {
          if (
            e.target instanceof HTMLElement &&
            (e.target.id === 'modalOverlay' || e.target.id === 'modalContent')
          ) {
            if (onClickBackground != null) {
              onClickBackground()
            }
          }
        }}
      >
        <ModalContent id="modalContent">{children}</ModalContent>
      </ModalOverlay>
    </>,
    document.getElementById('modal-root') as HTMLElement
  )
}

export default Modal
