import styled from 'styled-components'
import { Block } from '@/components/base/Block'
import { useApp } from '@/hooks/useApp'
import { Heading2 } from '@/components/base/Heading'
import { type ReactNode } from 'react'
import { align } from '@/styles/display'
import { Col } from '../base/Layout'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.main`
  flex: 1;
`

interface Props {
  leading?: ReactNode
  title: string
  action?: ReactNode
  backgroundColor?: V1AppColorTheme
}

const SPHeader = ({
  title,
  leading,
  action,
  backgroundColor
}: Props): JSX.Element => {
  const app = useApp()
  return (
    <Block borderBottom backgroundColor={backgroundColor}>
      <Container>
        <Col span={6} align={align.FLEX_START}>
          {leading}
        </Col>
        <Col span={12}>
          <TitleContainer>
            <Heading2 textColor={app.theme.mainTextColor}>{title}</Heading2>
          </TitleContainer>
        </Col>
        <Col span={6} align={align.FLEX_END}>
          {action}
        </Col>
      </Container>
    </Block>
  )
}

export default SPHeader
