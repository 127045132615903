import { useAuth } from '@/hooks/useAuth'
import { useNavigator } from '@/hooks/useNavigator'
import { fontSize, fontWeight } from '@/styles/font'
import { type V1Image, type V1IconType } from 'my-recruiter-app-ts-if'
import styled from 'styled-components'
import { PrimaryButton } from '@/components/base/Button'
import { LocalIconType, Icon } from '@/components/base/Icon'
import NavigationListSP from './NavigationListSP/NavigationListSP'
import { useApp } from '@/hooks/useApp'
import { Link, matchPath } from 'react-router-dom'
import { AppPath } from '@/routes/routes'
import { buttonSize } from '../base/Button/Base'
import { Block } from '../base/Block'
import spaceStyle from '@/styles/space'
import { showErrorToast } from '../SnackBar/BaseSnackBar'
import { size } from '@/styles/device'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  flex: none;
  box-sizing: border-box;

  @media (max-width: ${size.mobile}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    flex: none;
    width: auto;
  }
`

const NavigationSPWrapper = styled.div`
  background-color: white;
  z-index: 11;
`

const StyledNavigationLogoSP = styled.span`
  display: none;

  @media (max-width: ${size.laptop}) {
    display: block;
    font-size: 24px;
    cursor: pointer;
  }
`

const StyledCompanyImage = styled.span`
  cursor: pointer;

  @media (max-width: ${size.mobile}) {
    display: flex;
    align-items: center;
  }
`

const StyledNavigationList = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${size.laptop}) {
    display: none;
  }
`

const StyledNavigationContent = styled.div`
  text-align: center;
  font-size: ${fontSize.S};
  margin: 0 16px;
  cursor: pointer;
`

const StyledAuthActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${size.laptop}) {
    display: none;
  }
`

export interface Navigation {
  name: string
  icon: V1IconType | LocalIconType
  onClick: () => void
}

export interface HeaderState {
  isNavigationListOpen: boolean
}

interface Props {
  headerState: HeaderState
  iconImage: V1Image
  navigationListPC: Navigation[]
  navigationListSP: Navigation[]
  onClickOpenNavigationList: () => void
  onClickCloseNavigationList: () => void
}

const Header = ({
  headerState,
  iconImage,
  navigationListPC,
  navigationListSP,
  onClickOpenNavigationList,
  onClickCloseNavigationList
}: Props): JSX.Element => {
  const { isNavigationListOpen } = headerState
  const { signOut, isAuthenticated } = useAuth()
  const app = useApp()
  const nav = useNavigator()

  const executeSignOut = async (): Promise<void> => {
    const result = await signOut()
    if (result.success) {
      // 状態を破棄することで、ユーザーごとの設定値を再度読み直せるようにしています。
      location.href = AppPath.SignIn
    } else {
      showErrorToast(result.message)
    }
  }

  return (
    <StyledContainer>
      {isNavigationListOpen ? (
        <NavigationSPWrapper>
          <NavigationListSP
            navigationList={navigationListSP}
            onClickCloseIcon={() => {
              onClickCloseNavigationList()
            }}
          />
        </NavigationSPWrapper>
      ) : (
        <>
          {isAuthenticated && (
            <StyledNavigationLogoSP
              onClick={() => {
                onClickOpenNavigationList()
              }}
            >
              <Icon
                iconType={LocalIconType.Menu}
                iconStyle={{ fontSize: fontSize.XXL }}
              />
            </StyledNavigationLogoSP>
          )}
          <StyledCompanyImage>
            <Link to={AppPath.Top}>
              <img
                src={iconImage.url}
                alt="company logo"
                width={iconImage.width}
                height="auto"
              />
            </Link>
          </StyledCompanyImage>
          {navigationListPC.length > 0 && (
            <StyledNavigationList>
              {navigationListPC.map((navigation: Navigation, index) => {
                return (
                  <StyledNavigationContent
                    key={index}
                    onClick={() => {
                      navigation.onClick()
                      onClickCloseNavigationList()
                    }}
                  >
                    {navigation.name}
                  </StyledNavigationContent>
                )
              })}
            </StyledNavigationList>
          )}
          {!app.havePrivatePage || (
            <Block margin={{ left: spaceStyle.M }}>
              <StyledAuthActions>
                <LogoutButton
                  isAuthed={isAuthenticated}
                  onClick={() => {
                    void executeSignOut()
                  }}
                />
                <LoginButton
                  isAuthed={isAuthenticated}
                  onClick={() => {
                    void nav?.gotoSignIn()
                    onClickCloseNavigationList()
                  }}
                />
              </StyledAuthActions>
            </Block>
          )}
        </>
      )}
    </StyledContainer>
  )
}

interface LoginButtonProps {
  onClick: () => void
  isAuthed: boolean
}

const LoginButton = (props: LoginButtonProps): JSX.Element => {
  const location = window.location
  const isHiddenLogoutButton =
    [
      AppPath.SignIn,
      AppPath.SignUpComplete,
      AppPath.SignUp,
      AppPath.Entry,
      AppPath.Term,
      AppPath.Privacy,
      AppPath.JobSeekerSchedulePreview
    ].filter((e, index) => {
      return matchPath({ path: e }, location.pathname)
    }).length > 0

  if (isHiddenLogoutButton || props.isAuthed) {
    return <></>
  }

  return (
    <PrimaryButton height={buttonSize.M} onClick={props.onClick}>
      ログイン
    </PrimaryButton>
  )
}

interface LogoutButtonProps {
  onClick: () => void
  isAuthed: boolean
}

const LogoutButton = (props: LogoutButtonProps): JSX.Element => {
  const location = window.location
  const isHiddenButton =
    [AppPath.SignUp, AppPath.JobSeekerSchedulePreview].filter((e, index) => {
      return matchPath({ path: e }, location.pathname)
    }).length > 0

  if (isHiddenButton || !props.isAuthed) {
    return <></>
  }

  return (
    <Block>
      <PrimaryButton
        fontSize={fontSize.S}
        fontWeight={fontWeight.Bold}
        height={buttonSize.M}
        width="120px"
        onClick={props.onClick}
      >
        ログアウト
      </PrimaryButton>
    </Block>
  )
}

export default Header
