import { type PayloadFavorite } from '@/components/JobPosting/JobPostingCard/JobPostingCardType2'
import { showToast } from '@/components/SnackBar/BaseSnackBar'
import { useJobPostingServiceFavoriteStatusChange } from '@/feature/JobPosting/api/postJobPostingNotifyFavoriteStatusChange'
import {
  V1FavoriteStatusType,
  type V1GetJobPostingCardsSearchBody,
  type V1JobPostingFilterPanelComponent
} from 'my-recruiter-app-ts-if'
import type React from 'react'
import { createContext, useContext, useState, type ReactNode } from 'react'

interface TFavoriteJobPostingIdsStorageContext {
  updateFavoriteJobPostingIds: (data: PayloadFavorite) => void
}

export const FavoriteJobPostingIdsStorageContext =
  createContext<TFavoriteJobPostingIdsStorageContext>({
    updateFavoriteJobPostingIds: () => {}
  })

export interface TSavedSearchConditionStorage {
  searchData: V1JobPostingFilterPanelComponent
  searchBody: V1GetJobPostingCardsSearchBody
}

interface TSavedJobPostingSearchConditionStorageContext {
  savedJobPostingSearchConditons: TSavedSearchConditionStorage[]
  updateSavedJobPostingSearchConditons: (
    items: TSavedSearchConditionStorage[]
  ) => void
  deleteSavedJobPostingSearchConditons: (index: number) => void
}

export const SavedJobPostingSearchConditionStorageContext =
  createContext<TSavedJobPostingSearchConditionStorageContext>({
    savedJobPostingSearchConditons: [],
    updateSavedJobPostingSearchConditons: () => {},
    deleteSavedJobPostingSearchConditons: () => {}
  })

export const JobPostingStorageProvider: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  const useJobPostingServiceFavoriteStatusChangeMutation =
    useJobPostingServiceFavoriteStatusChange()

  const updateFavoriteJobPostingIds = (data: PayloadFavorite): void => {
    const jobPostingId = data.job_posting_id

    void useJobPostingServiceFavoriteStatusChangeMutation
      .mutateAsync({
        jobPostingId,
        body: {
          status: data.status
            ? V1FavoriteStatusType.Like
            : V1FavoriteStatusType.Dislike
        }
      })
      .then(() => {
        !!data.status && showToast('求人をお気に入りしました。')
      })
      .catch(async (err) => {
        console.error(err)
      })
  }

  const savedJobPostingSearchConditonsKey = 'saved_job_posting_search_conditons'

  const savedJobPostingSearchConditonsStrOrNull = localStorage.getItem(
    savedJobPostingSearchConditonsKey
  )
  let tmpSavedJobPostingSearchConditons: TSavedSearchConditionStorage[] = []
  if (savedJobPostingSearchConditonsStrOrNull != null) {
    tmpSavedJobPostingSearchConditons = JSON.parse(
      savedJobPostingSearchConditonsStrOrNull
    )
  }

  const [savedJobPostingSearchConditons, setSavedJobPostingSearchConditons] =
    useState(tmpSavedJobPostingSearchConditons)

  const updateSavedJobPostingSearchConditons = (
    items: TSavedSearchConditionStorage[]
  ): void => {
    if (items.length > 100) {
      items = items.slice(0, 100)
    }
    setSavedJobPostingSearchConditons(items)
    localStorage.setItem(
      savedJobPostingSearchConditonsKey,
      JSON.stringify(items)
    )
  }

  const deleteSavedJobPostingSearchConditons = (index: number): void => {
    let items = savedJobPostingSearchConditons
    if (index >= 0 && index <= items.length) {
      items.splice(index, 1)
    }
    if (items.length > 100) {
      items = items.slice(0, 100)
    }
    setSavedJobPostingSearchConditons(items)
    localStorage.setItem(
      savedJobPostingSearchConditonsKey,
      JSON.stringify(items)
    )
  }

  return (
    <FavoriteJobPostingIdsStorageContext.Provider
      value={{
        updateFavoriteJobPostingIds
      }}
    >
      <SavedJobPostingSearchConditionStorageContext.Provider
        value={{
          savedJobPostingSearchConditons,
          updateSavedJobPostingSearchConditons,
          deleteSavedJobPostingSearchConditons
        }}
      >
        {children}
      </SavedJobPostingSearchConditionStorageContext.Provider>
    </FavoriteJobPostingIdsStorageContext.Provider>
  )
}

export function useFavoriteJobPostingIdsStorage(): TFavoriteJobPostingIdsStorageContext {
  return useContext(FavoriteJobPostingIdsStorageContext)
}

export function useSavedJobPostingSearchConditionStorage(): TSavedJobPostingSearchConditionStorageContext {
  return useContext(SavedJobPostingSearchConditionStorageContext)
}
