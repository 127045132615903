import styled from 'styled-components'
import { Block } from '@/components/base/Block'
import FooterContainer from '@/feature/Builder/Component/containers/FooterContainer/FooterContainer'
import HeaderContainer from '@/feature/Builder/Component/containers/HeaderContainer/HeaderContainer'
import spaceStyle from '@/styles/space'
import { size } from '@/styles/device'
import BaseSnackBar from '../SnackBar/BaseSnackBar'
import { AppPath } from '@/routes/routes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Body = styled.main`
  flex: 1;
`

const Main = ({ children }: PageLayoutProps): JSX.Element => {
  const location = window.location

  const noMargin = [AppPath.Entry, AppPath.Term, AppPath.Privacy].includes(
    location.pathname as AppPath
  )

  return (
    <Body style={{ margin: noMargin ? undefined : '0px 12px' }}>
      {children}
    </Body>
  )
}

const MainContainer = ({ children }: PageLayoutProps): JSX.Element => {
  const location = window.location

  const noMaximunWidth = [
    AppPath.Entry,
    AppPath.Term,
    AppPath.Privacy
  ].includes(location.pathname as AppPath)

  return (
    <Block
      margin={{ horizontal: spaceStyle.AUTO }}
      maxWidth={noMaximunWidth ? undefined : size.laptop}
    >
      <Block margin={{ vertical: noMaximunWidth ? undefined : spaceStyle.M }}>
        {children}
      </Block>
    </Block>
  )
}

interface PageLayoutProps {
  children: JSX.Element
}

const PageLayout = ({ children }: PageLayoutProps): JSX.Element => {
  return (
    <Container>
      <HeaderContainer />
      <Main>
        <MainContainer>{children}</MainContainer>
      </Main>
      <FooterContainer />
      <BaseSnackBar onClickCloseIcon={() => {}}></BaseSnackBar>
    </Container>
  )
}

export default PageLayout
