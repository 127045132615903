import { Header } from '@/components/Header'
import { type Navigation, type HeaderState } from '@/components/Header/Header'
import SPHeader from '@/components/Header/SPHeader'
import { PageTitleMap } from '@/components/Layout/util'
import { Block } from '@/components/base/Block'
import { IconButton } from '@/components/base/Button'
import { LocalIconType } from '@/components/base/Icon'
import { useApp } from '@/hooks/useApp'
import { useAuth } from '@/hooks/useAuth'
import { useJobPostingAdvancedSearchConditionBackup } from '@/hooks/useJobPostingAdvancedSearchConditionBackup'
import useIsMobile from '@/hooks/useMobile'
import { useNavigator } from '@/hooks/useNavigator'
import { AppPath } from '@/routes/routes'
import { fontSize } from '@/styles/font'
import {
  V1IconType,
  V1NavigateActionType,
  V1RouteType
} from 'my-recruiter-app-ts-if'
import { memo, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

const HeaderContainer = (): JSX.Element => {
  const app = useApp()
  const location = useLocation()
  const [showMenuHeader, setShowMenuHeader] = useState(true)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (
      matchPath(
        { path: AppPath.JobPostingOfferAcceptance },
        location.pathname
      ) != null
    ) {
      setShowMenuHeader(false)
    } else {
      switch (location.pathname) {
        case AppPath.Profile:
        case AppPath.JobPostingSearchConditions:
        case AppPath.ChangePassword:
        case AppPath.ForgotPassword:
        case AppPath.ForgotPasswordComplete:
        case AppPath.ConfirmForgotPassword:
        case AppPath.ConfirmForgotPasswordComplete:
        case AppPath.SignUp:
        case AppPath.SignUpComplete:
        case AppPath.SignIn:
        case AppPath.JobSeekerSchedule:
        case AppPath.ChangeID:
        case AppPath.ChangeIDRequested:
        case AppPath.ChangeIDConfirm:
        case AppPath.Setting:
        case AppPath.Entry:
        case AppPath.Privacy:
        case AppPath.Term:
        case AppPath.JobSeekerMessageList:
          setShowMenuHeader(false)
          app.screenTitle = PageTitleMap[location.pathname]
          break
        default: {
          const isJobSeekerPreview =
            matchPath(
              { path: AppPath.JobSeekerSchedulePreview },
              location.pathname
            ) != null
          if (isJobSeekerPreview) {
            setShowMenuHeader(false)
            app.screenTitle = PageTitleMap[AppPath.JobSeekerSchedule]
            break
          }
          setShowMenuHeader(true)
        }
      }
    }

    return () => {}
  }, [location.pathname])

  return (
    <>
      {isMobile ? (
        <Block>
          {showMenuHeader ? (
            <MenuHeaderContainer />
          ) : (
            <SPHeaderContainer title={app.screenTitle} />
          )}
        </Block>
      ) : (
        <Block>
          <MenuHeaderContainer />
        </Block>
      )}
    </>
  )
}

interface SPHeaderContainerProps {
  title: string
}

const SPHeaderContainer = (props: SPHeaderContainerProps): JSX.Element => {
  const app = useApp()
  const nav = useNavigator()
  const [showBackButton, setShowBackButton] = useState(true)

  useEffect(() => {
    switch (location.pathname) {
      case AppPath.Entry:
      case AppPath.Privacy:
      case AppPath.Term:
        setShowBackButton(false)
        break
      default:
        setShowBackButton(true)
    }

    return () => {}
  }, [location.pathname])

  return (
    <SPHeader
      title={props.title}
      backgroundColor={app.headerBackroundColor}
      leading={
        showBackButton ? (
          <IconButton
            icon={LocalIconType.ArrowBackIos}
            color={app.theme.mainTextColor}
            fontSize={fontSize.XXL}
            onClick={() => {
              void nav?.goBack()
            }}
          />
        ) : (
          <Block height="50px" />
        )
      }
    />
  )
}

const MenuHeaderContainer = (): JSX.Element => {
  const app = useApp()
  const nav = useNavigator()
  const [, updateJobPostingAdvancedSearchConditionBackup] =
    useJobPostingAdvancedSearchConditionBackup()
  const { isAuthenticated } = useAuth()

  const iconImage = app.iconImage
  const navigationListPC =
    app.pcHeaderMenu.items?.map((item): Navigation => {
      return {
        name: item.text ?? '',
        icon: item.iconType ?? V1IconType.Unspecified,
        onClick: () => {
          if (
            item.navigateActionType === V1NavigateActionType.GotoSpecificRoute
          ) {
            if (V1RouteType.JobPostingAdvancedSearch === item.routeType) {
              updateJobPostingAdvancedSearchConditionBackup(undefined)
            }
            if (
              V1RouteType.FavoriteJobPostings === item.routeType &&
              item.iconType === V1IconType.History
            ) {
              void nav?.gotoBrowsingHistory()
              return
            }

            if (V1RouteType.Url === item.routeType) {
              window.open(item.url ?? '', '_blank')
              return
            }

            void nav?.goToRouteByRouteType(item.routeType)
          }
        }
      }
    }) ?? []

  const navigationListSP =
    app.hamburgerMenu.items?.map((item): Navigation => {
      return {
        name: item.text ?? '',
        icon: item.iconType ?? V1IconType.Unspecified,
        onClick: () => {
          if (
            item.navigateActionType === V1NavigateActionType.GotoSpecificRoute
          ) {
            if (V1RouteType.FavoriteJobPostings === item.routeType) {
              item.iconType === V1IconType.History
                ? nav?.gotoBrowsingHistory()
                : nav?.gotoFavoriteJobposting()

              return
            }

            if (V1RouteType.Url === item.routeType) {
              window.open(item.url ?? '', '_blank')
              return
            }

            void nav?.goToRouteByRouteType(item.routeType)
          }
        }
      }
    }) ?? []

  const [headerState, setHeaderState] = useState<HeaderState>({
    isNavigationListOpen: false
  })

  const onClickOpenNavigationList = (): void => {
    setHeaderState({
      isNavigationListOpen: true
    })
  }
  const onClickCloseNavigationList = (): void => {
    setHeaderState({
      isNavigationListOpen: false
    })
  }

  const getNavigationListForPC = (): Navigation[] => {
    const isHiddenNavigationList =
      [AppPath.JobSeekerSchedulePreview].filter((e, index) => {
        return matchPath({ path: e }, location.pathname)
      }).length > 0
    if (isHiddenNavigationList) return []

    return isAuthenticated ? navigationListPC : []
  }

  return (
    <Block borderBottom>
      <Header
        headerState={headerState}
        iconImage={iconImage}
        navigationListPC={getNavigationListForPC()}
        navigationListSP={navigationListSP}
        onClickOpenNavigationList={onClickOpenNavigationList}
        onClickCloseNavigationList={onClickCloseNavigationList}
      />
    </Block>
  )
}

export default memo(HeaderContainer)
