import { AppPath } from '@/routes/routes'

export const PageTitleMap = {
  [AppPath.Profile as string]: 'プロフィール',
  [AppPath.JobPostingSearchConditions as string]: '保存した検索条件',
  [AppPath.ChangePassword as string]: 'パスワード再設定',
  [AppPath.ForgotPassword as string]: 'パスワード再設定',
  [AppPath.ForgotPasswordComplete as string]: 'パスワード再設定',
  [AppPath.ConfirmForgotPassword as string]: 'パスワード再設定',
  [AppPath.ConfirmForgotPassword as string]: 'パスワード設定',
  [AppPath.FavoriteJobposting as string]: 'お気に入り',
  [AppPath.JobSeekerSchedule as string]: 'スケジュール共有',
  [AppPath.SignUp as string]: 'アカウント作成',
  [AppPath.SignUpComplete as string]: 'アカウント作成',
  [AppPath.SignIn as string]: 'ログイン',
  [AppPath.ChangeID as string]: 'ログインID設定',
  [AppPath.ChangeIDConfirm as string]: 'ログインID設定',
  [AppPath.Setting as string]: 'アカウント設定',
  [AppPath.JobSeekerMessageList as string]: '受信メールー覧'
}
