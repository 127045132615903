import { Footer } from '@/components/Footer'
import { useApp } from '@/hooks/useApp'
import { type Navigation } from '@/components/Footer'
import { V1IconType } from 'my-recruiter-app-ts-if'
import { memo, useEffect, useState } from 'react'
import { Block } from '@/components/base/Block'
import { AppPath } from '@/routes/routes'
import AppAssets from '@/styles/app_assets'
import useIsMobile from '@/hooks/useMobile'
import { useLocation, matchPath } from 'react-router-dom'

const FooterContainer = (): JSX.Element => {
  const app = useApp()
  const isMobile = useIsMobile()
  const [onlyDesktop, setOnLyDesktop] = useState(false)

  const location = useLocation()

  const footerNavigations =
    app.footerMenu.items?.map((item): Navigation => {
      return {
        name: item.text ?? '',
        icon: item.iconType ?? V1IconType.Unspecified,
        link: item.url ?? ''
      }
    }) ?? []

  useEffect(() => {
    switch (location.pathname) {
      case AppPath.FavoriteJobposting:
      case AppPath.JobSeekerSchedule:
      case AppPath.ChangeID:
      case AppPath.ChangeIDRequested:
      case AppPath.ChangeIDConfirm:
      case AppPath.Setting:
      case AppPath.JobSeekerMessageList:
        setOnLyDesktop(true)
        break

      default: {
        const isJobSeekerPreview =
          matchPath(
            { path: AppPath.JobSeekerSchedulePreview },
            location.pathname
          ) != null

        setOnLyDesktop(isJobSeekerPreview)
      }
    }

    return () => {}
  }, [location.pathname])

  return (
    <Block laptop={onlyDesktop}>
      <Footer
        company={
          [
            AppPath.Entry.toString(),
            AppPath.Privacy.toString(),
            AppPath.Term.toString()
          ].includes(location.pathname) ? (
            <Block cursor="default">
              <img
                src={AppAssets.icCompanyProfile}
                alt="company logo"
                width={isMobile ? 40 : 80}
                height={isMobile ? 40 : 80}
              />
            </Block>
          ) : (
            <Block cursor="default">
              <img
                src={app.iconImage.url}
                alt="company logo"
                width={app.iconImage.width}
                height="auto"
              />
            </Block>
          )
        }
        footerNavigations={footerNavigations}
        footerCopyright={app.footerCopyright}
      />
    </Block>
  )
}

export default memo(FooterContainer)
