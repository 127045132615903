import React, { type ReactNode, useContext, useState, useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  type V1GetAppResponse,
  type V1AppTheme,
  type V1AppColorTheme,
  V1AppThemeType,
  type V1NavigationListComponent,
  type V1Image
} from 'my-recruiter-app-ts-if'
import _ from 'lodash'
import { appServiceApi } from '@/api/app-service-api'

export const getApp = async (): Promise<V1GetAppResponse> => {
  return await appServiceApi.appServiceGetApp()
}

export const AppContext = React.createContext<{
  app?: App
  dispatch: (data: App) => void
}>({ dispatch: () => {} })

export class AppTheme implements Jss.Theme {
  public mainTextColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.87)'
  }

  public subTextColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.54)'
  }

  public inactiveColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.38)'
  }

  public frameColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.12)'
  }

  public borderColor: V1AppColorTheme = {
    color: '#0000001F'
  }

  public borderDarkColor: V1AppColorTheme = {
    color: '#00000033'
  }

  public lightGrayColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.03)'
  }

  public placeholderColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.6)'
  }

  public whiteTextColor: V1AppColorTheme = {
    color: '#FFFFFF'
  }

  public primaryMainColor: V1AppColorTheme = {
    color: '#1F85FD'
  }

  public primaryDarkColor: V1AppColorTheme = {
    color: '#0059C9'
  }

  public primaryLightColor: V1AppColorTheme = {
    color: '#70B4FF'
  }

  public primaryUltraLightColor: V1AppColorTheme = {
    color: '#E9F3FF'
  }

  public secondaryMainColor: V1AppColorTheme = {
    color: '#F7618F'
  }

  public secondaryDarkColor: V1AppColorTheme = {
    color: '#C02B62'
  }

  public secondaryLightColor: V1AppColorTheme = {
    color: '#FF94BF'
  }

  public secondaryUltraLightColor: V1AppColorTheme = {
    color: '#FEEFF4'
  }

  public accentColor: V1AppColorTheme = {
    color: '#E62F82'
  }

  public accentSubColor: V1AppColorTheme = {
    color: '#FDEAF3'
  }

  public accentMainColor: V1AppColorTheme = {
    color: '#B00020'
  }

  public errorMainColor: V1AppColorTheme = {
    color: '#B00020'
  }

  public errorUltralightColor: V1AppColorTheme = {
    color: '#F7E6E9'
  }

  public whiteColor: V1AppColorTheme = {
    color: '#FFFFFF'
  }

  public linkColor: V1AppColorTheme = {
    color: '#1a0dab'
  }

  public likeColor: V1AppColorTheme = {
    color: '#F7618F'
  }

  public likeUltralightColor: V1AppColorTheme = {
    color: '#FEEFF4'
  }

  public alertMediumColor: V1AppColorTheme = {
    color: '#F8BA34'
  }

  public alertMediumUltralightColor: V1AppColorTheme = {
    color: '#FEEFCF'
  }

  public disabledColor: V1AppColorTheme = {
    color: '#9E9E9E'
  }

  public transparent: V1AppColorTheme = {
    color: 'transparent'
  }

  public redColor: V1AppColorTheme = {
    color: '#FF424A'
  }

  public grayBackroundColor: V1AppColorTheme = {
    color: 'rgba(0, 0, 0, 0.12)'
  }

  public yellowColor: V1AppColorTheme = {
    color: '#FFFF67'
  }

  public fromAppThemeType(appThemeType?: V1AppThemeType): V1AppColorTheme {
    switch (appThemeType) {
      case V1AppThemeType.MainTextColor:
        return this.mainTextColor
      case V1AppThemeType.SubTextColor:
        return this.subTextColor
      case V1AppThemeType.InactiveColor:
        return this.inactiveColor
      case V1AppThemeType.DisabledColor:
        return this.disabledColor
      case V1AppThemeType.FrameColor:
        return this.frameColor
      case V1AppThemeType.BorderColor:
        return this.borderColor
      case V1AppThemeType.BorderDarkColor:
        return this.borderDarkColor
      case V1AppThemeType.LightGrayColor:
        return this.lightGrayColor
      case V1AppThemeType.PlaceholderColor:
        return this.placeholderColor
      case V1AppThemeType.WhiteTextColor:
        return this.whiteTextColor
      case V1AppThemeType.PrimaryMainColor:
        return this.primaryMainColor
      case V1AppThemeType.PrimaryDarkColor:
        return this.primaryDarkColor
      case V1AppThemeType.PrimaryLightColor:
        return this.primaryLightColor
      case V1AppThemeType.PrimaryUltraLightColor:
        return this.primaryUltraLightColor
      case V1AppThemeType.SecondaryMainColor:
        return this.secondaryMainColor
      case V1AppThemeType.SecondaryDarkColor:
        return this.secondaryDarkColor
      case V1AppThemeType.SecondaryLightColor:
        return this.secondaryLightColor
      case V1AppThemeType.SecondaryUltraLightColor:
        return this.secondaryUltraLightColor
      case V1AppThemeType.AccentColor:
        return this.accentColor
      case V1AppThemeType.AccentSubColor:
        return this.accentSubColor
      case V1AppThemeType.AccentMainColor:
        return this.accentMainColor
      case V1AppThemeType.RedColor:
        return this.redColor
      case V1AppThemeType.YellowColor:
        return this.yellowColor
      default:
        return this.mainTextColor
    }
  }

  static fromApiAppTheme(srcTheme: V1AppTheme): AppTheme {
    const retTheme = new AppTheme()

    if (!_.isEmpty(srcTheme.mainTextColor)) {
      retTheme.mainTextColor = srcTheme.mainTextColor
    }
    if (!_.isEmpty(srcTheme.subTextColor)) {
      retTheme.subTextColor = srcTheme.subTextColor
    }
    if (!_.isEmpty(srcTheme.inactiveColor)) {
      retTheme.inactiveColor = srcTheme.inactiveColor
    }
    if (!_.isEmpty(srcTheme.frameColor)) {
      retTheme.frameColor = srcTheme.frameColor
    }
    if (!_.isEmpty(srcTheme.borderColor)) {
      retTheme.borderColor = srcTheme.borderColor
    }
    if (!_.isEmpty(srcTheme.borderDarkColor)) {
      retTheme.borderDarkColor = srcTheme.borderDarkColor
    }
    if (!_.isEmpty(srcTheme.lightGrayColor)) {
      retTheme.lightGrayColor = srcTheme.lightGrayColor
    }
    if (!_.isEmpty(srcTheme.placeholderColor)) {
      retTheme.placeholderColor = srcTheme.placeholderColor
    }
    if (!_.isEmpty(srcTheme.whiteTextColor)) {
      retTheme.whiteTextColor = srcTheme.whiteTextColor
    }
    if (!_.isEmpty(srcTheme.primaryMainColor)) {
      retTheme.primaryMainColor = srcTheme.primaryMainColor
    }
    if (!_.isEmpty(srcTheme.primaryDarkColor)) {
      retTheme.primaryDarkColor = srcTheme.primaryDarkColor
    }
    if (!_.isEmpty(srcTheme.primaryLightColor)) {
      retTheme.primaryLightColor = srcTheme.primaryLightColor
    }
    if (!_.isEmpty(srcTheme.primaryUltraLightColor)) {
      retTheme.primaryUltraLightColor = srcTheme.primaryUltraLightColor
    }
    if (!_.isEmpty(srcTheme.secondaryMainColor)) {
      retTheme.secondaryMainColor = srcTheme.secondaryMainColor
    }
    if (!_.isEmpty(srcTheme.secondaryDarkColor)) {
      retTheme.secondaryDarkColor = srcTheme.secondaryDarkColor
    }
    if (!_.isEmpty(srcTheme.secondaryLightColor)) {
      retTheme.secondaryLightColor = srcTheme.secondaryLightColor
    }
    if (!_.isEmpty(srcTheme.secondaryUltraLightColor)) {
      retTheme.secondaryUltraLightColor = srcTheme.secondaryUltraLightColor
    }
    if (!_.isEmpty(srcTheme.accentColor)) {
      retTheme.accentColor = srcTheme.accentColor
    }
    if (!_.isEmpty(srcTheme.accentSubColor)) {
      retTheme.accentSubColor = srcTheme.accentSubColor
    }
    if (!_.isEmpty(srcTheme.redColor)) {
      retTheme.redColor = srcTheme.redColor
    }
    if (!_.isEmpty(srcTheme.yellowColor)) {
      retTheme.yellowColor = srcTheme.yellowColor
    }
    if (!_.isEmpty(srcTheme.errorMainColor)) {
      retTheme.errorMainColor = srcTheme.errorMainColor
    }

    return retTheme
  }
}

export class App {
  title: string = ''
  clientName: string = ''
  faviconPath: string = ''
  theme: AppTheme = new AppTheme()
  havePrivatePage: boolean = false
  iconImage: V1Image = {}
  pcHeaderMenu: V1NavigationListComponent = { items: [] }
  hamburgerMenu: V1NavigationListComponent = { items: [] }
  footerMenu: V1NavigationListComponent = { items: [] }
  footerCopyright: string = ''
  isLoading: boolean = true
  screenTitle: string = ''
  headerBackroundColor?: V1AppColorTheme
  getAppResponse?: V1GetAppResponse
  isError?: boolean
}

export const ProvideApp: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  const queryClient = useQueryClient()
  const appQuery = useQuery({
    queryKey: ['app'],
    queryFn: async () => {
      try {
        return await getApp()
      } catch (error) {
        void queryClient.cancelQueries({ queryKey: ['app'] })
      }
    }
  })

  const [appData, setAppData] = useState(new App())
  const wrap = {
    app: appData,
    dispatch: setAppData
  }

  useEffect(() => {
    if (!appQuery.isSuccess && appQuery.fetchStatus === 'idle') {
      const dataApp = {
        isError: true,
        getAppResponse: undefined
      }
      setAppData({ ...appData, ...dataApp })
    }
  }, [appQuery.fetchStatus])

  useEffect(() => {
    const app = new App()
    const { data } = appQuery
    if (data === undefined) return () => {}

    const theme = data.theme
    const footerMenu = data.footerMenu
    const pcHeaderMenu = data.pcHeaderMenu
    const hamburgerMenu = data.hamburgerMenu
    const iconImage = data.iconImage

    app.title = data.title ?? ''
    app.clientName = data.clientName ?? ''
    app.faviconPath = data.faviconPath ?? ''
    app.havePrivatePage = data.havePrivatePage ?? false
    app.footerCopyright = data.footerCopyright ?? ''
    app.isLoading = false
    app.getAppResponse = data
    app.isError = !appQuery.isSuccess

    if (!_.isEmpty(theme)) {
      app.theme = AppTheme.fromApiAppTheme(theme)
    }

    if (!_.isEmpty(footerMenu)) {
      app.footerMenu = footerMenu
    }

    if (!_.isEmpty(pcHeaderMenu)) {
      app.pcHeaderMenu = pcHeaderMenu
    }

    if (!_.isEmpty(hamburgerMenu)) {
      app.hamburgerMenu = hamburgerMenu
    }

    if (!_.isEmpty(iconImage)) {
      app.iconImage = iconImage
    }
    setAppData(app)
    return () => {}
  }, [appQuery.isSuccess])

  return <AppContext.Provider value={wrap}>{children}</AppContext.Provider>
}

export const useApp = (): App => {
  const { app } = useContext(AppContext)
  return app ?? new App()
}
