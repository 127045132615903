import { memo } from 'react'
import Base, { type ButtonProps } from './Base'
import { useApp } from '@/hooks/useApp'
import borderLine, { borderSize } from '@/styles/border'
import { fontWeight } from '@/styles/font'

const Sub = (props: ButtonProps): JSX.Element => {
  const app = useApp()
  return (
    <Base
      bgColor={app.theme.whiteTextColor}
      textColor={app.theme.subTextColor}
      border={{
        size: borderSize.S,
        line: borderLine.Solid,
        color: app.theme.frameColor
      }}
      fontWeight={fontWeight.Bold}
      {...props}
    />
  )
}

export default memo(Sub)
