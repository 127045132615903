import { fontSize, fontStyle, fontWeight } from '@/styles/font'
import spaceStyle, { buildSpaceStyle } from '@/styles/space'
import styled from 'styled-components'
import { type TextProps } from './props'
import { useApp } from '@/hooks/useApp'
import { cursor } from '@/styles/cursor'

const Styled = styled.p<TextProps>`
  font-size: ${(props) =>
    props.fontSize !== undefined ? props.fontSize : fontSize.S};
  font-weight: ${(props) =>
    props.fontWeight !== undefined ? props.fontWeight : fontWeight.Regular};
  font-style: ${(props) =>
    props.fontStyle !== undefined ? props.fontStyle : fontStyle.Normal};
  text-align: ${(props) => props.textAlign !== undefined && props.textAlign};
  line-height: ${(props) => props.lineHeight !== undefined && props.lineHeight};
  margin: ${(props) => {
    return props.margin !== undefined ? buildSpaceStyle(props.margin) : ''
  }};
  padding: ${(props) => {
    return props.padding !== undefined
      ? buildSpaceStyle(props.padding)
      : spaceStyle.NONE
  }};
  color: ${(props) => {
    return props.textColor !== undefined ? props.textColor.color : 'black'
  }};
  ${(props) =>
    (props.truncate ?? false) &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  white-space: ${(props) => {
    return props.whiteSpace !== undefined && props.whiteSpace
  }};
  cursor: ${(props) =>
    props.cursor === undefined ? cursor.DEFAULT : props.cursor};

  ${(props) =>
    props.maxLines !== undefined &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${props.maxLines};
  -webkit-box-orient: vertical;
    `}
  ${(props) =>
    (props.underline ?? false) &&
    `
      text-decoration: underline;
    `}
    word-break: break-word;
`

const StyledAnchor = styled.a<{ color?: string }>`
  display: block;
  color: ${(props) => (props.color !== undefined ? props.color : 'black')};
  text-decoration: none;
  cursor: pointer;
`

const BaseText = (props: TextProps): JSX.Element => {
  const app = useApp()
  const { children, href, ...rest } = props
  if (href !== undefined) {
    return (
      <Styled {...rest}>
        <StyledAnchor
          href={href}
          style={{ color: app.theme.primaryMainColor.color }}
        >
          {children}
        </StyledAnchor>
      </Styled>
    )
  }
  return <Styled {...rest}>{children}</Styled>
}

export default BaseText
