import EntryContainer from '../containers/EntryContainer'
import { type CompanyItem, defaultCompanys } from '../components/Company'

function PrivacyPage(): JSX.Element {
  const privacies = [
    'https://kango.mynavi.jp/privacy/',
    'https://pharma.mynavi.jp/privacy/',
    'https://co-medical.mynavi.jp/privacy/',
    'https://kaigoshoku.mynavi.jp/privacy/',
    'https://hoiku.mynavi.jp/privacy/'
  ]

  const newArray: CompanyItem[] = defaultCompanys.map((value, index) => {
    return { ...value, url: privacies[index] }
  })

  return (
    <EntryContainer
      title="個人情報の取り扱い"
      subTitle="各サービスの「個人情報の取り扱い」は以下よりご参照ください"
      items={newArray}
    />
  )
}

export default PrivacyPage
