import { Block } from '@/components/base/Block'
import { Heading3 } from '@/components/base/Heading'
import { Text3 } from '@/components/base/Text'
import { align } from '@/styles/display'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { memo } from 'react'

export const UnknownErrorPage = memo(
  (): JSX.Element => (
    <Block
      textAlign={align.LEFT}
      maxWidth="600px"
      margin={{ horizontal: spaceStyle.AUTO }}
    >
      <Heading3
        fixedSPfontSize
        fontSizeSP={fontSize.L}
        margin={{ vertical: spaceStyle.XL }}
      >
        不明なエラーが発生しました。
      </Heading3>
      <Text3 margin={{ vertical: spaceStyle.NONE }}>
        不明なエラーが発生している可能性がございます。
        大変申し訳ございませんが、しばらく経ってから 再度お試しください。
      </Text3>
    </Block>
  )
)

UnknownErrorPage.displayName = 'UnknownErrorPage'
