import { jobPostingServiceApi } from '@/api/job-posting-service-api'
import { type MutationConfig } from '@/lib/react-query'
import { handleQueryError } from '@/utils/error'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import {
  type FetchError,
  type JobPostingServiceFavoriteStatusChangeOperationRequest,
  type JobPostingServiceNotifyFavoriteStatusChangeRequest
} from 'my-recruiter-app-ts-if'

export const jobPostingServiceNotifyFavoriteStatusChange = async (
  requestParameters: JobPostingServiceNotifyFavoriteStatusChangeRequest
): Promise<object> => {
  return await jobPostingServiceApi.jobPostingServiceNotifyFavoriteStatusChange(
    requestParameters
  )
}

interface UseJobPostingServiceNotifyFavoriteStatusChangeOptions {
  config?: MutationConfig<typeof jobPostingServiceNotifyFavoriteStatusChange>
}

export const jobPostingServiceNotifyFavoriteStatusChangeMutationKey: string =
  'job_posting_service_notify_favorite_status_change'

export const useJobPostingServiceNotifyFavoriteStatusChange = ({
  config
}: UseJobPostingServiceNotifyFavoriteStatusChangeOptions = {}): UseMutationResult<
  object,
  FetchError,
  JobPostingServiceNotifyFavoriteStatusChangeRequest,
  unknown
> => {
  return useMutation({
    mutationKey: [jobPostingServiceNotifyFavoriteStatusChangeMutationKey],
    onError: (err) => {
      handleQueryError(err).catch((error) => {
        console.error('Failed to handle error:', error)
      })
      return err
    },
    ...config,
    mutationFn: jobPostingServiceNotifyFavoriteStatusChange
  })
}

// change status favorite job
export const jobPostingServiceFavoriteStatusChangeMutationKey: string =
  'job_posting_service_favorite_status_change'

export const jobPostingServiceFavoriteStatusChange = async (
  requestParameters: JobPostingServiceFavoriteStatusChangeOperationRequest
): Promise<object> => {
  return await jobPostingServiceApi.jobPostingServiceFavoriteStatusChange(
    requestParameters
  )
}

interface UseJobPostingServiceFavoriteStatusChangeStatus {
  config?: MutationConfig<typeof jobPostingServiceFavoriteStatusChange>
}

export const useJobPostingServiceFavoriteStatusChange = ({
  config
}: UseJobPostingServiceFavoriteStatusChangeStatus = {}): UseMutationResult<
  object,
  FetchError,
  JobPostingServiceFavoriteStatusChangeOperationRequest,
  unknown
> => {
  return useMutation({
    mutationKey: [jobPostingServiceFavoriteStatusChangeMutationKey],
    onError: (err) => {
      handleQueryError(err).catch((error) => {
        console.error('Failed to handle error:', error)
      })
      return err
    },
    ...config,
    mutationFn: jobPostingServiceFavoriteStatusChange
  })
}
