const textAlign = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
  START: 'start'
} as const

export type TextAlignType = (typeof textAlign)[keyof typeof textAlign]

export default textAlign
